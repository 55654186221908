import Header from "../../shared/Header/NoLinkHeader";
import Footer from "../../shared/Footer/Footer";
import ErrorCircle from '@mui/icons-material/Error';

const RegistrationError = () => {
    return (
        <div>
            <Header/>

            <div style={{marginTop: "17vh", textAlign: "center"}}>
                <ErrorCircle style={{color: "red", transform: 'scale(4)'}}/>
            </div>

            <h2 style={{color: "white", margin: "5vh", textAlign: "center", fontSize: "3.5vh"}}>
                Whoops...
            </h2>

            <div style={{paddingBottom: "12vh"}} >
                <p style={{color: "white", margin: "11vh", textAlign: "center", fontSize: "2.5vh"}}>
                    There was an error creating your account. Try again.
                </p>
            </div>

            <Footer/>
        </div>
    );
}

export default RegistrationError;