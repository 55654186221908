import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";
import {  signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase.js';
import {useEffect, useState} from "react";
import getFutureEvents from "./getFutureEvents.js";

async function loginUser(credentials) {
    const toastId = toast.loading("Attempting to login user...")
    const email = credentials['email']
    const password = credentials['password']

    const response = await signInWithEmailAndPassword(auth, email, password)
        .then(userCredentials => userCredentials.user)
        .catch(error => error);
    
    const type = Object.prototype.toString.call(response).split(" ")[1]
    const final = type.slice(0, type.length - 1).toLowerCase()
    
    if (final == 'error') {
        toast.dismiss()
        toast.error(response.message, {id: toastId})
        return null
    }

    // checking if user is an admin by seeing what the response is to a get events call
    const isAdmin = await getFutureEvents(response)
    
    if (isAdmin['errorCode'] && isAdmin['errors']) {
        toast.dismiss()
        toast.error(isAdmin['message'], {id: toastId})
        return null
    }

    if (final == 'object') {
        toast.dismiss()
        toast.success("Logged In!", {id: toastId})
        return response
    }
    

}

export default loginUser;