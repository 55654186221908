import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ArrowBack from '@mui/icons-material/ArrowBack';

const EventInformationModal = ({ show, setShow, user }) => {
    return (
        <Modal
            show={show}
            keyboard={false}
            size='lg'
        >
            <Modal.Header style={{textAlign: "center"}}>
                <Modal.Title className='w-100'>Event Adding Assistance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {!user &&
                    <>
                        {/* <Col xs='auto'>
                        <Image
                            // src={Redeem_Photo}
                            src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/eventViewScreenshot.png"
                            style={{maxWidth: "400px"}}/>
                        </Col> */}
                        <Col>
                            <p style={{fontWeight: "bold"}}>
                                Redeem Events are simpler. 
                            <span style={{fontWeight: "normal"}}>
                                <br/>- We handle the cover photo
                                <br/>- Rewards points are set to zero.
                                <br/>- Event Radius is set to zero.
                                <br/>- No sport field exists.</span>
                                <span><br/>We handle all that, so you don't have to.</span>
                            </p>
                            {/* <p style={{marginTop: "30px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Map Location</span> - This specifies the location of the redeem event.
                                While we
                                provide preset locations, feel free to click on the map to create custom locations.
                            </p>
                            <p style={{marginTop: "0"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Preset Cover Photo and Title</span></p>

                            <p style={{marginTop: "20px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Date, Time, and Location</span></p>
                            <p style={{marginTop: "50px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Description</span> - Additional information about the event.
                            </p> */}
                        </Col>
                        </>
                    }
                    {user && <>
                        <Col xs='auto'>
                            <Image
                                src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/eventViewScreenshot.png"
                                style={{maxWidth: "400px"}}/>
                        </Col>
                        <Col>
                            <p style={{fontWeight: "bold", marginTop: "20%"}}>This is a sample of how form fields appear to users of the app. 
                                Hopefully it helps you orient yourself when creating events. Happy event adding!
                            </p>
                            <p style={{marginTop: "40px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Cover Photo</span> - It has a size limit of 1MB. The squarer
                                the photo, the better.</p>
                            <p style={{marginTop: "100px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Title</span> - The title of the event.</p>
                            <p style={{marginTop: "-20px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Time</span> - The Date and Time of the Event.</p>
                            <p style={{marginTop: "-10px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Reward Points</span> - The number of points a user earns for
                                attending the event. Default is 10.</p>
                            <p style={{marginTop: "-10px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Description</span> - Additional information about the event.
                            </p>
                            <p style={{marginTop: "30px"}}><ArrowBack/><span
                                style={{fontWeight: "bold"}}>Map Location</span> - This specifies the location of the event.
                                While we
                                provide preset locations, feel free to click on the map to create custom event locations.
                                Adjust the radius
                                to enlarge or shrink the area in which users can check in.
                            </p>
                        </Col>
                        <div style={{marginLeft: "5%", marginTop: "10px"}}>
                            <p style={{fontWeight: "bold"}}>Other Parameters</p>
                            {user && <p style={{marginLeft: "5%", marginRight: "15%"}}><span style={{fontWeight: "bold"}}>Game of the Week</span> -
                                a special identifier for important events.</p>}
                            <p style={{marginLeft: "5%", marginRight: "15%"}}><span style={{fontWeight: "bold"}}>Visible to Users</span> -
                                When disabled, only admin can view this event. Can be changed later on by editing the event.</p>
                        </div>
                    </>}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EventInformationModal;