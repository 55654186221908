// src/components/Header/Header.js
import React from 'react';
import './Header.css';
import { Outlet, Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FanstandLogo } from './Header';

const HeaderAdmin = () => {
    return (
        <>
            <header id="nostickheader">
                <Row className="correctSizedRow">
                    <FanstandLogo />
                    <Col style={{display: 'flex', flexDirection: 'column-reverse'}}>
                        <nav>
                            <ul>
                                <li>
                                    <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '1.5vw'}}
                                         to="/admin">
                                    <div className="headerLink">
                                        Events
                                    </div>
                                </Link>
                                </li>

                                {/* Until later my sweet... *blows kiss and looks longingly at Eddie */}
                                {/* <li>
                                    <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '21px'}}
                                         to="/admin/analytics">
                                    <div className="headerLink">
                                        Analytics
                                    </div>
                                </Link>
                                </li> */}

                                <li>
                                    <Link style={{color: 'inherit', textDecoration: 'inherit', fontSize: '1.5vw'}}
                                         to="/admin/notifications">
                                    <div className="headerLink">
                                        Notifications
                                    </div>
                                </Link>
                                </li>
                                
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </header>

        </>
    );
};

export default HeaderAdmin;
