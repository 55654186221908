import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import React from "react";
import Button from "react-bootstrap/Button";
import './About.css'
import { MobileFanstandLogo } from "../../shared/Header/MobileHeader";
import Footer from "../../shared/Footer/MobileFooter";
import { Outlet, Link } from "react-router-dom";


function SimeoneModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/simeoneHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Joe Simeone</h3>
                            <h6 className='mobile'>Founder and Architect</h6>
                            <p>
                                Joe is the Founder and Lead Architect at 201 Interactive.
                                Formerly working as a full-stack developer at Rational Enterprise and a current Computer
                                Science major at Hamilton College,
                                Joe is responsible for coordinating all development at 201.
                            </p>
                            <a href="https://www.linkedin.com/in/josephsimeone/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function TrenkModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/trenkHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Eddie Trenk</h3>
                            <h6 className='mobile'>React Developer</h6>
                            <p>
                                Eddie is a React developer for 201 Interactive, working on creating and maintaining our website and developing analytics features for our Fanstand product.
                                Eddie is currently a Computer Science and Math double major at Hamilton College.
                            </p>
                            <a href="https://www.linkedin.com/in/eddie-trenk/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function IngramModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/ingramHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Ben Ingram</h3>
                            <h6 className='mobile'>Cloud Architect</h6>
                            <p>
                                As the Cloud Architect at 201 Interactive, Ben leverages knowledge of Microsoft Azure and Docker to reliably deploy our products.
                                Ben previously worked as a cloud solutions intern at Thorben Consulting and is currently a History major at Hamilton College.
                            </p>
                            <a href="https://www.linkedin.com/in/benjamin-e-ingram/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function BadamiModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/badamiHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Alex Badami</h3>
                            <h6 className='mobile'>iOS Developer</h6>
                            <p>
                                As an iOS Developer at 201 Interactive, Alex implements and tests designs for our mobile applications. Alex is a Computer Science and Physics double major at Hamilton College.
                            </p>
                            <a href="https://www.linkedin.com/in/alexbadami/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function WilliamsModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/williamsHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Hugh Williams</h3>
                            <h6 className='mobile'>UI/UX Designer</h6>
                            <p>
                                Currently a Math and Music double major at Hamilton College,
                                Hugh is head of User Experience at 201 Interactive and is responsible for our branding and interface design.
                            </p>
                            <a href="https://www.linkedin.com/in/hugheswilliams/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function RassiasModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/rassiasHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>Jackson Rassias</h3>
                            <h6 className='mobile'>Communication and Marketing</h6>
                            <p>
                                As Director of Communications and Marketing at 201 Interactive, Jackson is responsible for ensuring user satisfaction and pursuing potential partnerships.
                                Jackson is a Public Policy and Creative Writing double major at Hamilton College.
                            </p>
                            <a href="https://www.linkedin.com/in/jacksonrassias/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

function GallagherModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <Image className="modalImage" src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/gallagherHeadshot.png" roundedCircle/>
                        </Col>
                        <Col>
                            <h3>James Gallagher</h3>
                            <h6 className='mobile'>React Developer</h6>
                            <p>
                                As a React Developer at 201 Interactive, James designs, builds, and tests our web products.
                                James is a senior Computer Science and Math double major at Hamilton College.
                            </p>
                            <a href="https://www.linkedin.com/in/james-gallagher-hamxc24/" target="_blank"
                               rel="noopener noreferrer">
                                <img className='linkedinImage' src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/linkedin-app-icon.webp" alt='LinkedIn Logo'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

const MobileAbout = () => {

    const [simeoneModalShow, simeoneSetModalShow] = React.useState(false);
    const [trenkModalShow, trenkSetModalShow] = React.useState(false);
    const [ingramModalShow, ingramSetModalShow] = React.useState(false);
    const [badamiModalShow, badamiSetModalShow] = React.useState(false);
    const [williamsModalShow, williamsSetModalShow] = React.useState(false);
    const [rassiasModalShow, rassiasSetModalShow] = React.useState(false);
    const [gallagherModalShow, gallagherSetModalShow] = React.useState(false);

    return (
        <div>
            <header className="header" style={{paddingLeft: "15px", paddingRight: "15px"}}>
                <Row className="correctSizedRow">
                    <MobileFanstandLogo />
                </Row>
            </header>

            {/* <div className="about_us">
                <h2 className="mobile">
                    About Us
                </h2>
                <h4 className="mobile">
                    Fanstand boosts campus engagement by driving attendance and support for college
                    athletics.
                    Our app acts as a hub that alerts students to events near them, connects them with
                    friends, and rewards them when they engage with campus activities.
                    For administrators, Fanstand provides unparalleled insight into student activity with a
                    robust analytics dashboard and allows athletic departments to promote upcoming events
                    through targeted push notifications.
                    Fanstand is for any school looking to improve student engagement and campus programming.
                </h4>
                <h4>
                    Fanstand is the first app produced by software development startup 201 Interactive.
                </h4>
            </div>

            <div className="history">
                <h2 className="mobile">
                    Our History
                </h2>
                <h4 className="mobile">
                    Fanstand was founded by a group of Hamilton College student-athletes devoted to
                    strengthening college communities around the country.
                </h4>
            </div> */}

            <div className="history">
                <h2 className="mobile">
                    Our History
                </h2>
                <h4 className="mobile">
                    Fanstand was created by a group of Hamilton College student-athletes.
                    Our founders deeply value the vibrant community that surrounds them at
                    practice, at games, and even walking through campus.
                    At Fanstand, we are committed to bringing that same sense of belonging and support to athletes and
                    fans across the country. To learn more, send us an email at <a href={"mailto:info@fanstandapp.com"}>info@fanstandapp.com</a>
                </h4>
            </div>

            <section id="leadershp cards" style={{backgroundColor: "black"}}>
                <h4 style={{color: 'white', fontSize: "6vw"}}>
                    Meet our leadership team:
                </h4>
                <Col>
                <Row className="imageRow" xs={1} sm={1} md={3} lg={3} xl={3} xxl={3}>
                    <Col>

                    </Col>
                    <Col>
                        <div className="simeone-button">
                            <Button variant="btn-outline-primary" onClick={() => simeoneSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/simeoneHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Joe Simeone
                                </h4>
                                <h5 className="mobile">
                                    Founder and Architect
                                </h5>
                            </Button>
                            <SimeoneModal
                                show={simeoneModalShow}
                                onHide={() => simeoneSetModalShow(false)}
                            />
                        </div>
                    </Col>
                    <Col>

                    </Col>
                </Row>
                <Row className="imageRow" xs={1} sm={1} md={3} lg={3} xl={3} xxl={3}>
                    <Col>
                        <div className="badami-button">
                            <Button variant="btn-outline-primary"
                                    onClick={() => badamiSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/badamiHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Alex Badami
                                </h4>
                                <h5 className="mobile">
                                    iOS Developer
                                </h5>
                            </Button>
                            <BadamiModal
                                show={badamiModalShow}
                                onHide={() => badamiSetModalShow(false)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="gallagher-button">
                            <Button variant="btn-outline-primary" onClick={() => gallagherSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/gallagherHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    James Gallagher
                                </h4>
                                <h5 className="mobile">
                                    React Developer
                                </h5>
                            </Button>
                            <GallagherModal
                                show={gallagherModalShow}
                                onHide={() => gallagherSetModalShow(false)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="ingram-button">
                            <Button variant="btn-outline-primary" onClick={() => ingramSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/ingramHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Ben Ingram
                                </h4>
                                <h5 className="mobile">
                                    Cloud Architect
                                </h5>
                            </Button>
                            <IngramModal
                                show={ingramModalShow}
                                onHide={() => ingramSetModalShow(false)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="imageRow" xs={1} sm={1} md={3} lg={3} xl={3} xxl={3}>
                    <Col>
                        <div className="rassias-button">
                            <Button variant="btn-outline-primary" onClick={() => rassiasSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/rassiasHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Jackson Rassias
                                </h4>
                                <h5 className="mobile">
                                    Communications and Marketing
                                </h5>
                            </Button>
                            <RassiasModal
                                show={rassiasModalShow}
                                onHide={() => rassiasSetModalShow(false)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="trenk-button">
                            <Button variant="btn-outline-primary" onClick={() => trenkSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/trenkHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Eddie Trenk
                                </h4>
                                <h5 className="mobile">
                                    React Developer
                                </h5>
                            </Button>
                            <TrenkModal
                                show={trenkModalShow}
                                onHide={() => trenkSetModalShow(false)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="williams-button">
                            <Button variant="btn-outline-primary" onClick={() => williamsSetModalShow(true)}>
                                <Image
                                    className='aboutUsCoverImage'
                                    src="https://fanstandblobs.blob.core.windows.net/images/websiteAssets/williamsHeadshot.png"
                                    roundedCircle/>
                                <h4 className="mobile">
                                    Hugh Williams
                                </h4>
                                <h5 className="mobile">
                                    UI/UX Designer
                                </h5>
                            </Button>
                            <WilliamsModal
                                show={williamsModalShow}
                                onHide={() => williamsSetModalShow(false)}
                            />
                        </div>
                    </Col>
                </Row>
                </Col>
            </section>
            

            <Footer/>
        </div>
    );
};

export default MobileAbout;
