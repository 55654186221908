import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function getFutureEvents(token) {
    const endpoint = SERVER_ADDRESS + '/api/v1/event/events/future'
    const toastId = toast.loading("Attempting to load events...")

    const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token['accessToken']
        },
    }).then(data => data.json()).then(json => json)

    if (response[0]['eventId']) {
        toast.dismiss()
        toast.success("Events were retrieved successfully!", {id: toastId})
        return response
    } else {
        toast.dismiss()
        toast.error("Error occurred when retrieving events.", {id: toastId})
        return ['error']
    }
}

export default getFutureEvents;