import {SERVER_ADDRESS} from "../constants";
import toast from "react-hot-toast";

async function verifyEmail(token) {
    const endpoint = SERVER_ADDRESS + `/api/v1/user/registrationConfirm?token=${token}`

    return fetch(endpoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data)
}

export default verifyEmail;