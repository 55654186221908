import Header from "../../shared/Header/NoLinkHeader";
import Footer from "../../shared/Footer/Footer";
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate, useSearchParams} from "react-router-dom";
import verifyEmail from "../../shared/hooks/verifyEmail";

const RegistrationConfirm = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    verifyEmail(searchParams.get('token')).then(r => {
        if (r.status !== 200) {
            //bring up registration error page
            navigate("/registrationError")
        } else {
            //bring up registration success page
            navigate("/registrationSuccess")
        }
        });

    return (
        <div>
            <Header/>

            <h2 style={{color: "white", marginTop: "20vh", textAlign: "center", fontSize: "2.5vh"}}>
                Please give us a moment as we set up your account.
            </h2>

            <div style={{textAlign: "center", margin: "5vh"}}>
                <Spinner animation="border" role="status" style={{color: "white"}}>
                </Spinner>
            </div>

            <h2 style={{color: "white", marginBottom: "39vh", textAlign: "center", fontSize: "2.5vh"}}>
                It should only take a few seconds.            
            </h2>

            <Footer/>
        </div>
    );
};

export default RegistrationConfirm;